<template>
  <component :is="resolveLayout">
    <nprogress-container></nprogress-container>
    <router-view :key="$route.fullPath"></router-view>
  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    NprogressContainer,
  },
  computed: {
    ...mapGetters(['isDarkThemeEnabled', 'isUserAuthenticated',
      'userInfo']),
  },
  watch: {
    isUserAuthenticated: {
      handler() {
        this.setAuthHeader() // Update the Authorization header when the authentication status changes
      },
      immediate: true, // Call the handler immediately after registration, so it also runs on component mount
    },
  },
  mounted() {
    this.$vuetify.theme.dark = this.isDarkThemeEnabled
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401 || error.response.status === 503) {
          if (error.response.status === 503) {
            console.log('Server is down')
            this.$toast.error(error.response.data.message)
          }
          if (this.$router.currentRoute.name !== 'login') {
            this.$router.replace({ name: 'login' })
            this.clearUser()
          }
        }

        return Promise.reject(error)
      },
    )
  },
  methods: {
    ...mapActions(['clearUser']),
    setAuthHeader() {
      delete axios.defaults.headers.common.Authorization
      if (this.isUserAuthenticated) {
        axios.defaults.headers.common.Authorization = `Bearer ${this.userInfo.authenticationToken}`
      }
    },
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
}
</script>

<style>
.clickable-data-table tbody tr:hover {
  cursor: pointer;
}
</style>
