import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export const AUTHENTICATE_USER = 'AUTHENTICATE_USER'
export const CLEAR_USER = 'CLEAR_USER'
export const TOGGLE_DARK_THEME = 'TOGGLE_DARK_THEME'
export const SET_AUTO_LOGOUT_TIME = 'SET_AUTO_LOGOUT_TIME'
export const TOGGLE_AUTO_LOGOUT = 'TOGGLE_AUTO_LOGOUT'
export const REFRESH_STATIONS = 'REFRESH_STATIONS'
export const SET_SELECTED_STATION = 'SET_SELECTED_STATION'
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME'

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    appName: process.env.VUE_APP_NAME,
    logo: process.env.VUE_APP_LOGO,
    companyName: '',
    autoLogoutTime: 15,
    meterReadingDate: 25,
    isAutoLogoutEnabled: true,
    isDarkThemeEnabled: false,
    refreshStations: true,
    selectedStation: null,
    isUserAuthenticated: false,
    userInfo: {
      name: '',
      email: '',
      phone: '',
      permissions: '',
      authenticationToken: '',
      routerLoginLink: '',
    },
  },
  getters: {
    isDarkThemeEnabled: state => state.isDarkThemeEnabled,
    autoLogoutTime: state => state.autoLogoutTime,
    isAutoLogoutEnabled: state => state.isAutoLogoutEnabled,
    shouldRefreshStations: state => state.refreshStations,
    meterReadingDate: state => state.meterReadingDate,
    appName: state => state.appName,
    logo: state => state.logo,
    companyName: state => state.companyName,
    selectedStation: state => state.selectedStation,
    isUserAuthenticated: state => state.isUserAuthenticated,
    userInfo: state => state.userInfo,
    userPermissions: state => state.userInfo.permissions,
  },
  mutations: {
    [SET_AUTO_LOGOUT_TIME](state, payload) {
      state.autoLogoutTime = payload
    },
    [TOGGLE_DARK_THEME](state) {
      state.isDarkThemeEnabled = !state.isDarkThemeEnabled
    },
    [TOGGLE_AUTO_LOGOUT](state) {
      state.isAutoLogoutEnabled = !state.isAutoLogoutEnabled
    },
    [REFRESH_STATIONS](state, payload) {
      state.refreshStations = payload
    },
    [SET_SELECTED_STATION](state, payload) {
      state.selectedStation = payload
    },
    [SET_COMPANY_NAME](state, payload) {
      state.companyName = payload
    },
    [AUTHENTICATE_USER](state, payload) {
      state.isUserAuthenticated = true
      state.userInfo = payload
    },
    [CLEAR_USER](state) {
      state.isUserAuthenticated = false
      state.userInfo = {}
    },
  },
  actions: {
    setAutoLogoutTime({ commit }, payload) {
      commit(SET_AUTO_LOGOUT_TIME, payload)
    },
    toggleAutoLogout({ commit }) {
      commit(TOGGLE_AUTO_LOGOUT)
    },
    toggleDarkTheme({ commit }) {
      commit(TOGGLE_DARK_THEME)
    },
    refreshStations({ commit }, payload) {
      commit(REFRESH_STATIONS, payload)
    },
    setSelectedStation({ commit }, payload) {
      commit(SET_SELECTED_STATION, payload)
    },
    setCompanyName({ commit }, payload) {
      commit(SET_COMPANY_NAME, payload)
    },
    authenticateUser({ commit }, payload) {
      commit(AUTHENTICATE_USER, payload)
    },
    clearUser({ commit }) {
      commit(CLEAR_USER)
    },
  },
})
